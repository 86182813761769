import { COLORS } from 'core/constants';

export const standardHeaderProps = {
    bgAboveFold: false,
    bgColorBelowFold: 'white',
    bgColor: 'white',
    textColorAboveFold: COLORS.DARK_BLUE,
    textColorBelowFold: COLORS.DARK_BLUE,
};

export const darkLandingHeaderProps = {
    bgAboveFold: false,
    bgColorBelowFold: 'white',
    bgColor: 'white',
    textColorAboveFold: 'white',
    textColorBelowFold: COLORS.DARK_BLUE,
};

export const heroHeaderProps = {
    bgAboveFold: true,
    bgColorBelowFold: COLORS.WHITE,
    bgColor: COLORS.DARK_BLUE_20,
    textColorAboveFold: COLORS.WHITE,
    textColorBelowFold: COLORS.DARK_BLUE,
}

export const inverseHeaderProps = {
    bgAboveFold: false,
    bgColorBelowFold: COLORS.DARK_BLUE,
    bgColor: COLORS.DARK_BLUE,
    textColorAboveFold: 'white',
    textColorBelowFold: 'white',
};

export const configureHeaderProps = {
    bgAboveFold: false,
    bgColorBelowFold: 'white',
    bgColor: 'white',
    textColorAboveFold: COLORS.DARK_BLUE,
    textColorBelowFold: COLORS.DARK_BLUE,
};

export const secondaryHeaderProps = {
    bgAboveFold: true,
    bgColorBelowFold: COLORS.DARK_BLUE,
    bgColor: COLORS.DARK_BLUE,
    textColorAboveFold: 'white',
    textColorBelowFold: 'white',
};

export const consistentHeaderProps = {
    bgAboveFold: true,
    bgColorBelowFold: COLORS.WHITE,
    bgColor: COLORS.WHITE,
    textColorAboveFold: COLORS.DARK_BLUE,
    textColorBelowFold: COLORS.DARK_BLUE
}