import { setupAnalytics, setupBuildVersion, setupCoreTasks } from 'startup/preload-core';
import { COLORS } from 'core/constants';
import 'style/critical.scss';

async function setupApp() {
    const App = await import(/* webpackChunkName: "app" */ './app');
    await App.init();
}

const isSignIn = () => window.location.pathname === '/signin-oxefit';
const checkSignIn = () => {
    if (isSignIn()) {
        document.body.style.backgroundColor = COLORS.LIGHT_BLUE;
    }
};

async function ready() {
    checkSignIn();
    setupBuildVersion();
    setupAnalytics();
    await setupCoreTasks();
    await setupApp();
}

document.addEventListener('DOMContentLoaded', ready);
