import globalStore from '@resn/gozer-core/reactivity/global-store';
import { clamp } from '@resn/gozer-math';
import { SIZES } from 'core/constants';
import urlSearch from 'utils/url-search';
import { inRange } from '@resn/gozer-math';
import { TIME } from './constants';
import { standardHeaderProps } from 'data/header-data';
import { map, lerp } from '@resn/gozer-math';
// import env from 'core/env';
// import { deviceBenchmark } from 'utils/device-benchmark';

const store = globalStore({
    // ROUTING
    route: {
        page: '',
        pathname: '/',
        params: {},
        first: true,
    },

    navigating: false,

    // SITE SCALE
    scale: 1,

    scroll: 0,
    scrolling: false,
    scrollDir: 1,
    scrollDelta: 0,
    scrollLock: false,

    overlayHasOpened: false,
    scrollFromOverlayOpen: 0,

    showPromoBanner: true,
    promoBannerHeight: 1,

    needsResize: 0,
    navHeight: 0,

    pulse: 0,

    preoder: {
        barType: '',
        subscriptionType: '',
    },

    header: {
        ...standardHeaderProps,
        menuOverride: false,
        disableMenu: false,
    },

    panels: {
        xs1Open: false,
        subscriptionPackageId: 'flex',
        cart: false,
        product: false,
    },

    modals: {
        programOpen: false,
        programCardData: {},
        videoOpen: false,
        hlsVideoOpen: false,
        videoUrl: '',
        hlsVideoUrl: '',
        thumbnail: '',
        hlsVideoThumbnail: '',
    },

    headerRetractPr: 0,

    // Member login : This is set via the api now - @see core/api/bind-store
    user: null,
    signedUp: false, // Signup successful
    requestedInfo: false, // End request info submitted

    latestReaction: null,
    locations: {
        latestUser: null,
        usersAdded: [],
        usersRemoved: [],
    },

    // VIEWPORT
    viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
        pr: clamp(window.devicePixelRatio, 1, urlSearch.get('pixelRatio') || 1.5),
        aspectRatio: (state) => state.width / state.height,
    },

    menu: { open: false, opened: false },
    muted: true,
    disableMute: false,

    fullScreenVideo: {
        active: false,
    },

    bannerHeight: () => document.getElementsByClassName('promoBanner')[0]?.getBoundingClientRect().height,
    headerHeight: () => document.getElementsByClassName('header__inner')[0]?.getBoundingClientRect().height,

    heroVideoInView: true,

    sectionsActive: [],
    sectionsProgresses: [],
    sectionsProgresses0: [],

    mobile: window.innerWidth <= SIZES.MOBILE_THRESHOLD,
    desktop: window.innerWidth > SIZES.MOBILE_THRESHOLD,
    layout: (state) => (state.viewport.width > state.viewport.height ? 'landscape' : 'portrait'),

    testimonialY: 0,
    // CLOCK
    clock: {
        delta: 0,
        time: 0,

        startTime: Date.now(),
    },

    cartDetails: {
        cartTotalAmount: 0,
        cartProducts: [],
    },

    shopifyProducts: [],
});

export default store;
