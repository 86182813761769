import { passiveEvent } from '@resn/gozer-misc';

import './index.scss';

class VersionClass {
    init(log = true, open = true) {
        if (__ENV__ === 'live') return;

        if (log) this.log();

        this.create(open);
    }

    create(open = false) {
        this.element = document.createElement('div');
        this.element.innerHTML = `
        <div role="button" aria-label="Toggle build version" class="toggle"></div>
        <div class="build">
            ${__BUILD__} (${__ENV__})
        </div>
    `;

        this.element.classList.add('version');
        this.collapse = this.element.querySelector('.toggle');
        this.toggle = this.toggle.bind(this);
        this.collapse.addEventListener('click', this.toggle, passiveEvent);

        this.stats = document.createElement('div');
        this.stats.className = 'stats';
        this.element.querySelector('.build').appendChild(this.stats);

        document.body.appendChild(this.element);

        if (!open) this.toggle();
    }

    l;

    log() {
        console.groupCollapsed(
            `%c - %c ${__BUILD__} (${__ENV__}) %c`,
            'background:#000000; padding: 1px; border-radius: 3px 0 0 3px; color: #ffffff',
            'background:#ffffff; padding: 1px; border-radius: 0 3px 3px 0; color: #000000',
            'background:transparent',
        );

        console.log(__CONFIG__);
        console.groupEnd();
    }

    updateStats(data) {
        this.stats.innerHTML = JSON.stringify(data);
    }

    toggle() {
        this.element.classList.toggle('collapsed');
    }
}

const Version = new VersionClass();
export default Version;
