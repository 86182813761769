import Version from 'dev/version';
import Analytics from '@resn/gozer-core/analytics';
import config from '@resn/gozer-core/config';
import CoreTasks from 'core/tasks';

export const setupBuildVersion = () => {
    Version.init();
};

export const setupAnalytics = () => {
    Analytics.initialize({ gaID: config.GA_ID });
};

export const setupCoreTasks = async () => {
    await CoreTasks.run();
};
