import { getGPUTier } from 'detect-gpu';
import { isMobile, isTablet } from '@resn/gozer-env';

const deviceBenchmark = {
    fallback: false,
    quality: 'med',
};

const qualities = {
    0: 'low',
    1: 'med',
    2: 'med',
    3: 'high',
};

const overides = [
    { name: 'iris', tier: 1 },
    { name: 'apple m1', tier: 3 },
    { name: 'radeon r9 200', tier: 1 },
    // { name: 'intel', tier: 1 },
];

const getBenchmark = async () => {
    const gpuTier = await getGPUTier({
        desktopTiers: [0, 14, 40, 50],
        override: {
            isIpad: isTablet,
            isMobile: isMobile,
        },
    });

    overides.forEach((overide) => {
        if (gpuTier.gpu && gpuTier.gpu.includes(overide.name)) gpuTier.tier = overide.tier;
    });

    if (isTablet) gpuTier.tier = 0;
    if (isMobile && !gpuTier.gpu) gpuTier.tier = 3;

    Object.assign(deviceBenchmark, gpuTier);

    deviceBenchmark.quality = qualities[gpuTier.tier];
    deviceBenchmark.fallback = !isMobile && deviceBenchmark.quality === 'low';

    return deviceBenchmark;
};

if (__DEV__) window.deviceBenchmark = deviceBenchmark;

export { deviceBenchmark, getBenchmark };
