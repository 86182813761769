import { getBenchmark } from 'utils/device-benchmark';

import config, { initConfig } from '@resn/gozer-core/config';
import expose from '@resn/gozer-core/dev/dev-globals';
import runTasks from '@resn/gozer-core/dev/run-tasks';

import env from 'core/env';
import store from 'core/store';
import Copy from '@resn/gozer-core/copy';
import { loadSvgSprite } from '@resn/gozer-loading/loaders';

/** Import via webpack to prevent caching */
import copyDeckUrl from 'public/static/data/copy.json';

const debug = false;
const prefix = 'loader';
const svgVer = '3.0' // svgVer is incremental number for loading latest copy of file

const initializeEnv = () => {
    env.init('main');
    expose('env', env);
};

const initializeConfig = () => {
    initConfig();
    expose('config', config);
};

const initializeStore = () => {
    store.init();
    expose('store', store());
};

const initializeDevieBenchmark = async () => {
    const deviceBenchmark = await getBenchmark();

    console.log('deviceBenchmark', deviceBenchmark);

    expose('deviceBenchmark', deviceBenchmark);
};

const loadCopy = async () => {
    await Copy.load(copyDeckUrl);
};

const loadSvg = () => {
    loadSvgSprite(`${__CDN__}static/svg/svg.svg?v=${svgVer}`);
};

async function run() {
    await runTasks(
        [initializeEnv, initializeConfig, initializeStore, initializeDevieBenchmark, loadCopy, loadSvg],
        debug,
        prefix,
    );
}

export default { run };
